import Box from '@mui/material/Box';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import EmailIcon from '@mui/icons-material/Email';

const actions = [
  { icon: <EmailIcon />, name: 'Email', link: "mailto:eyi@merakihospitality.net" },
  { icon: <LinkedInIcon />, name: 'LinkedIn', link: "https://www.linkedin.com/in/estheryi1" },
  { icon: <MenuBookIcon />, name: 'Pineapple Faith', link: "https://pineapplefaith.wordpress.com" },
];

const FabButton = () => {
  const handleClick = (idx) => {
    window.open(actions[idx].link)
  }
  
  return (
    <SpeedDial
      ariaLabel="contact speeddial"
      FabProps={{
        sx: {
          bgcolor: '#5f093d',
          '&:hover': {
            bgcolor: '#5f093d',
          }
        }
      }}
      sx={{
        position: 'fixed',
        bottom: "5%",
        right: "5%",
        zIndex:"5",
      }}
      icon={
      <SpeedDialIcon 
        icon={
          <Box sx={{
            display: "flex",
            alignItems: "center",
          }}>
          <ConnectWithoutContactIcon />
        </Box>
        }
        openIcon={
        <Box sx={{ display: "flex", alignItems: "center", }}>
          <ConnectWithoutContactIcon />
        </Box>
        }
      />}
    >
      {actions.map((action, index) => (
        <SpeedDialAction
          FabProps={{
            sx: {
              bgcolor: '#EAEAEA',
              '&:hover': {
                bgcolor: '#EAEAEA',
              }
            }
          }}
          key={action.name}
          icon={action.icon}
          tooltipTitle={action.name}
          tooltipOpen
          onClick={() => handleClick(index)}
        />
      ))}
    </SpeedDial>
  )
}

export default FabButton